var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "agreement" }, [
      _c("h1", [_vm._v("隐私策略")]),
      _c("p", { staticStyle: { "font-size": "14px" } }, [
        _vm._v(
          " 欢迎阅读无限立方隐私声明（下称本声明）。本声明适用您在无限立方无偿或有偿使用无限立方产品（包括但不限于在线设计、营销活动、在线印刷等无限立方服务）。无限立方是深圳市太和物联信息技术有限公司（下称太和公司）运营的网站，本声明所述的无限立方产品为太和公司旗下产品。 "
        )
      ]),
      _c("u", [
        _vm._v(
          " 在使用无限立方产品前，请您务必仔细阅读并透彻理解本声明，在确认充分理解并同意后使用相关产品。一旦您开始使用无限立方各项服务，即表示您已充分理解并同意本声明。如对本声明内容有任何疑问、意见或建议，您可通过无限立方提供的各种联系方式与太和公司联系。 "
        )
      ]),
      _c("p", [
        _vm._v(
          " 太和公司承诺尊重和保护您的隐私，并将此作为太和公司事业活动的基础和社会责任及义务。因此在您访问无限立方网站时，太和公司会尽力帮助您维护互联网个人数据的控制权。 "
        )
      ]),
      _c("h3", [_vm._v("1.对您的信息的收集")]),
      _c("p", [
        _vm._v("为向您提供更好的服务，太和公司会要求您在进行 "),
        _c("u", [
          _vm._v(
            "注册无限立方网用户、产品购买、产品购买后应监管部门要求进行实名认证、在线咨询等情况下提供您的姓名(或名称)、地址、联络方式及身份证等有效证件信息，以便我们为您提供快速准确的优质服务。"
          )
        ])
      ]),
      _c("h3", [_vm._v("2.对您的信息的使用")]),
      _c("p", [
        _vm._v(
          " 您提供的信息将受到严格保密，太和公司使用您的信息只是为了更全面地了解您的需要并为您提供更佳的服务。在一些情况下，可能还会用您的信息与您取得联系以进行市场调查或为您提供我们认为您可能感兴趣的市场信息。 "
        )
      ]),
      _c("u", [
        _vm._v(
          " 若太和公司将信息用于本声明未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。 "
        )
      ]),
      _c("p", [
        _vm._v(
          " 如太和公司停止运营无限立方产品或服务，太和公司将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对太和公司所持有的与已关停业务相关的个人信息进行删除或匿名化处理。 "
        )
      ]),
      _c("h3", [_vm._v(" 3.对您的信息的控制 ")]),
      _c("p", [
        _vm._v(
          " 当您通过注册或其它方式向太和公司提供您的信息时，太和公司不会把您的信息共享、转让或出租给他人。您的许可总被首先保障，太和公司不会向任何不代表太和公司的第三方透露您的信息。除非根据法律、法规或监管部门按法律执行，太和公司不会在未经您事先知晓或许可的情况下，以与上述内容无关的方式随意使用或分享您提供给太和公司的信息。 "
        )
      ]),
      _c("p", [
        _vm._v(
          " 太和公司可能会将收集到的您的信息与来自第三方的数据进行整理或合并，并用于向您推广产品或服务。 "
        )
      ]),
      _c("u", [
        _vm._v(
          " 太和公司可能委托授权合作伙伴为您提供某些服务，太和公司仅会出于本隐私声明的合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的。 "
        )
      ]),
      _c("p", [
        _vm._v(
          " 在太和公司发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，太和公司会要求新的持有您个人信息的公司、组织继续受本隐私声明的约束，否则太和公司将要求该公司、组织和个人重新向您征求授权同意。 "
        )
      ]),
      _c("h3", [_vm._v("4.对您的信息的进入或修改")]),
      _c("p", [
        _vm._v(
          " 太和公司为了保护您的隐私和安全，将采取合理的措施验证身份，之后，您才能进入或修改您的信息。太和公司力求使您的信息保持准确，并为您提供了包括使您能够在线访问您的信息、注册数据的能力，使您可以更新或修改您的信息。请注意，从无限立方到第三方网站的链接仅作为一种方便服务提供给您，如果使用这些链接，您将离开无限立方，而第三方网站可能会收集与您有关的个人身份信息。因此，无限立方对第三方网站及其任何信息、软件或其他产品、材料、或因使用它们而可能获得的任何结果不予认可，也不作任何陈述和保证。 "
        )
      ]),
      _c("h3", [_vm._v("5.您的信息的安全性")]),
      _c("p", [
        _vm._v(
          " 太和公司已采取合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。您的数据将被存放在有密码控制的服务器中，因而任何对您资料的访问都将受到限制并被严格保护。 "
        )
      ]),
      _c("p", [
        _vm._v(
          " 在不幸发生个人信息安全事件后，太和公司将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、太和公司已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况太和公司将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，太和公司会采取合理、有效的方式发布公告。 "
        )
      ]),
      _c("p", [
        _vm._v(
          " 同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。 "
        )
      ]),
      _c("h3", [_vm._v("6.您的信息的披露")]),
      _c("u", [
        _vm._v(
          " 您充分知晓，以下情形中，太和公司共享、转让、公开披露您的个人信息无需事先征得您的授权同意： （1）与国家安全、国防安全有关的； （2）与公共安全、公共卫生、重大公共利益有关的； （3）与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的； （4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的； （5）您自行向社会公众公开的个人信息； （6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道； （7）保护太和公司及其客户的权利、财产，包括协议的执行和遵守适用于服务的策略； （8）保护太和公司及其雇员、客户和公众的权利、财产或安全。 "
        )
      ]),
      _c("p", [
        _vm._v(
          " 请知悉，根据适用的法律，若太和公司对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。 "
        )
      ]),
      _c("h3", [_vm._v("7.Cookie技术的使用")]),
      _c("p", [
        _vm._v(
          " 随着互联网对各项新技术的应用，太和公司为访客带来了完全个性化的体验。太和公司会使用Cookies的技术来分析网页的使用情形。Cookie是放置在您的电脑硬盘中的一段要求权限的文字。使用此项技术并不表示太和公司可自动获悉有关您的任何信息。太和公司可以确定您使用的计算机类型，但使用Cookies的主要目的还在于使您在访问无限立方网站时获得更佳的体验。 "
        )
      ]),
      _c("p", [
        _vm._v(
          " 同时您也拥有完全的自主权，可以将网络浏览器设置为通知您Cookie的放置请求，或者完全拒绝Cookie。您可以删除包含Cookie的文件。这些文件被保存为Internet浏览器的一部分。 "
        )
      ]),
      _c("h3", [_vm._v("8.如何处理未成年人的信息")]),
      _c("p", [
        _vm._v(
          " 太和公司推定您具有相应的民事行为能力。如您为未成年人，太和公司要求您请您的父母或监护人仔细阅读本隐私声明，并在征得您的父母或监护人同意的前提下使用太和公司的服务、产品或向太和公司提供信息。 "
        )
      ]),
      _c("p", [
        _vm._v(
          " 对于经父母或监护人同意使用太和公司的产品或服务而收集未成年人个人信息的情况，太和公司只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。 "
        )
      ]),
      _c("h3", [_vm._v("9.本声明的更改")]),
      _c("p", [
        _vm._v(
          " 本声明的解释权归结于太和公司，太和公司可能适时修订本隐私声明的条款，该修订构成本隐私声明的一部分。 "
        )
      ]),
      _c("u", [
        _vm._v(
          " 如该等修订造成您在本隐私声明下权利的实质减少，太和公司将在修订生效前通过在主页上显著位置提示或向您发送电子邮件或以其他方式通知您。在该种情况下，若您继续使用太和公司的服务，即表示同意受经修订的本隐私声明的约束。 "
        )
      ]),
      _c("h3", [_vm._v("10.管辖与法律适用")]),
      _c("u", [
        _vm._v(
          " 本隐私声明的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法 "
        )
      ]),
      _c("p", [
        _vm._v(
          " 若您和太和公司发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本隐私声明签订地（中国广东省深圳市市南山区）有管辖权的人民法院管辖。 "
        )
      ]),
      _c("p", [_vm._v(" 本声明自公布时生效。 ")]),
      _c("p", [_vm._v("以上声明，您可随时在无限立方平台阅读、复制或下载。")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }